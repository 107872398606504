import React  from 'react';
import * as R from 'ramda';
import { Divider } from '@scriptscouts/react-platform-client/src/components/common';

import {
  StyledContainer,
  StyledHeader,
  StyledBenefitFeatures,
  StyledCheckboxIcon,
  StyledBenefitsCardContainer,
  StyledBenefitContainer,
  StyledClockIcon,
  StyledBenefitHeader,
  StyledBenefitsCard
} from './style';

import { i18n } from '../../utils/i18n';

import BENEFITS from './benefits.json';

const BenefitsComponent = () => {
  return (
    <section id="benefits">
      <StyledContainer>
        <StyledHeader as="h2">{i18n('scriptScoutsBenefits')}</StyledHeader>
        <StyledBenefitsCardContainer>
          <StyledBenefitsCard>
            {R.map(({ id, label, features }) => {
              return (
                <StyledBenefitContainer key={id}>
                  <StyledBenefitHeader>
                    { i18n(label) }
                  </StyledBenefitHeader>
                  <Divider />
                  <StyledBenefitFeatures>
                    {
                      R.values(R.mapObjIndexed((val, key) => {
                        const IconImage = val ? StyledCheckboxIcon : StyledClockIcon;
                        return (
                          <p key={`${id}-benefit-${key}`}>
                            <IconImage alt=''/>
                            <span>{i18n(key)}</span>
                          </p>
                        );
                      }, features))
                    }
                  </StyledBenefitFeatures>
                </StyledBenefitContainer>
              );
            }, BENEFITS)}
          </StyledBenefitsCard>
        </StyledBenefitsCardContainer>
      </StyledContainer>
    </section>
  );
};

export default BenefitsComponent;
