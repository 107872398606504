import styled from 'styled-components';
import { Heading } from '@primer/react';
import { Input, AutoCompleteInput, Button } from '@scriptscouts/react-platform-client/src/components/common';
import { SearchIcon, LocationIcon } from '@primer/octicons-react';

import { colors } from '@scriptscouts/react-platform-client/src/utility/colors';
import {
  spacing,
  breakpoints
} from '@scriptscouts/react-platform-client/src/utility/measurements';

export const StyledContainer = styled.div`
  background: ${colors['surfaceBrandDark']};
  color: ${colors['textInvertedPrimary']};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${spacing['4xl']} ${spacing['lg']} 140px ${spacing['lg']};
  gap: ${spacing['2xl']};
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
  @media only screen and (min-width: ${breakpoints['md']}) {
    font-size: 18px;
  }
`;

export const StyledContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing['md']};
  text-align: center;
  line-height: 150%;
  max-width: 328px;

  p {
    margin: 0;
  }

  @media only screen and (min-width: ${breakpoints['sm']}) {
    max-width: 100%;
  }
`;

export const StyledHeader = styled(Heading)`
  margin: 0;
  font-family: Manrope;
  font-size: 30px;
  font-style: normal;
  font-weight: 800;
  line-height: 125%; /* 37.5px */

  @media only screen and (min-width: ${breakpoints['md']}) {
    font-size: 48px;
  }
`;

export const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;
  gap: ${spacing['xl']};

  #search-prescriptions-input-container {
    div {
      width: 100%;
      align-items: center;
      justify-content: center;
    }
  }

  @media only screen and (min-width: ${breakpoints['md']}) {
    flex-direction: row;
    align-items: flex-start;
  }
`;

export const StyledInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: ${spacing['lg']};
  @media only screen and (min-width: ${breakpoints['sm']}) {
    flex-direction: row;
  }
  @media only screen and (min-width: ${breakpoints['md']}) {
    max-width: 525px;
  }
`;

export const StyledDistanceInputsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: ${spacing['lg']};
  width: 100%;
  @media only screen and (min-width: ${breakpoints['md']}) {
    width: auto;
  }
`;

export const StyledPrescriptionSearchInput = styled(AutoCompleteInput)`
  span {
    padding-top: 4px;
  }
  input {
    font-size: 16px;
    font-weight: 600;
  }
  @media only screen and (min-width: ${breakpoints['md']}) {
    max-width: 525px;
  }
`;

export const StyledSearchImageIcon = styled(SearchIcon)`
  color: ${colors['textLightMuted']};
  height: 14px;
  width: 14px;
  padding-bottom: 5px;
`;

export const StyledZipcodeInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;

  @media only screen and (min-width: ${breakpoints['md']}) {
    width: auto;
  }
`;

export const StyledSearchRadiusContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 45%;

  @media only screen and (min-width: ${breakpoints['md']}) {
    width: auto;
  }
`;

export const StyledZipcodeInput = styled(Input)`
  span {
    padding-top: 4px;
  }
  input {
    font-size: 16px;
    font-weight: 600;
  }
  @media only screen and (min-width: ${breakpoints['md']}) {
    max-width: 125px;
  }
`;

export const StyledImageIcon = styled(LocationIcon)`
  color: ${colors['textLightMuted']};
  height: 14px;
  width: 14px;
  padding-bottom: 5px;
`;

export const StyledSearchButton = styled(Button)`
  width: 100%;
  color: ${colors['textSecondary']};
  @media only screen and (min-width: ${breakpoints['md']}) {
    max-width: 180px;
  }
`;

export const StyledSelectedMedicationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 30px;
  @media only screen and (min-width: ${breakpoints['md']}) {
    flex-direction: row;
  }
`;
