import styled from 'styled-components';
import { ThreeBarsIcon } from '@primer/octicons-react';
import { colors } from '@scriptscouts/react-platform-client/src/utility/colors';
import { spacing, breakpoints } from '@scriptscouts/react-platform-client/src/utility/measurements';
import { ActionMenu, ActionList } from '@primer/react';
import { Button } from '@scriptscouts/react-platform-client/src/components/common';

export const StyledNavBar = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: ${colors['surfaceBrandDark']};
  padding: 18px ${spacing['lg']};
  overflow-y: auto;

  @media only screen and (min-width: ${breakpoints['md']}) {
    padding: 18px ${spacing['xl']};
  }
  @media only screen and (min-width: ${breakpoints['lg']}) {
    padding: 18px ${spacing['2xl']};
  }
`;

export const StyledLinksContainer = styled.div`
  display: none;
  flex-direction: column;
  gap: ${spacing['2xl']};
  font-family: Manrope;
  align-items: center;
  justify-content: center;
  padding: 0 ${spacing['sm']};

  @media only screen and (min-width: ${breakpoints['md']}) {
    display: flex;
    flex-direction: row;
    gap: ${spacing['2xl']};
    align-items: center;
    justify-content: flex-end;
    padding: 0 ${spacing['xl']};
    margin-left: auto;
  }
`;

export const StyledMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  top: ${spacing['sm']};
  right: ${spacing['md']};
  z-index: 100;

  @media only screen and (min-width: ${breakpoints['md']}) {
    display: none;
  }

  #hamburger-menu-button span:nth-of-type(2) {
    display: none;
  }
`;

export const StyledRightAlignedContainer = styled.div`
  display: flex;
  gap: ${spacing['md']};
  align-items: center;
  justify-content: flex-end;
  @media only screen and (min-width: ${breakpoints['md']}) {
    justify-content: flex-end;
  }
`;

export const StyledHamburgerMenu = styled(ThreeBarsIcon)`
  display: inline-block;
  margin-left: ${spacing['sm']};
  height: 24px;
  width: 24px;
  color: ${colors['borderBase']};
  cursor: pointer;

  @media only screen and (min-width: ${breakpoints['md']}) {
    display: none;
  }

  &:hover {
    opacity: 0.1;
  }
`;

export const StyledActionMenuButton = styled(ActionMenu.Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  outline: none;

  &:focus {
    box-shadow: none;
  }
`;

export const StyledActionMenuOverlay = styled(ActionMenu.Overlay)`
  top: ${spacing['sm']};
  right: ${spacing['md']};
  background: ${colors['surfaceBrandDark']};
  border: none;
  box-shadow: none;
`;

export const StyledActionList = styled(ActionList)`
  background: ${colors['surfaceBrandDark']};
  border: none
`;

export const StyledActionListLinkItem = styled(ActionList.LinkItem)`
  color: ${colors['textInvertedPrimary']};
  text-decoration: none;
  padding: ${spacing['sm']} ${spacing['md']};
  display: block;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: ${colors['surfaceBrandLight']};
  }
`;

export const StyledActionMenu = styled(ActionMenu)``;

export const StyledLoginButton = styled(Button)`
  display: none;
  @media only screen and (min-width: ${breakpoints['sm']}) {
    display: block;
  }
`;

export const StyledLogo = styled.img`
  height: 45px;
  max-width: 180px;

  @media only screen and (min-width: ${breakpoints['sm']}) {
    height: 50px;
    max-width: 100%;
  }
`;
