import React from 'react';
import SearchDiscountsComponent from '../../Components/SearchDiscounts';
import ProcessComponent from '../../Components/Process';
import ReviewContainer from '../../Components/Reviews';
import BenefitsContainer from '../../Components/Benefits';

import { Box } from '@primer/react';

const Landing = () => {
  return (
    <Box>
      <SearchDiscountsComponent />
      <ProcessComponent />
      <BenefitsContainer />
      <ReviewContainer />
    </Box>
  );
};

export default Landing;
