import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDebouncedCallback } from 'use-debounce';
import * as R from 'ramda';
import { search } from '@scriptscouts/react-platform-client/src/utility/services/Medication';
import { Dropdown, Token } from '@scriptscouts/react-platform-client/src/components/common';

import { MedicationContext } from '../../Context/MedicationContext';

import ProvidersDisplayComponent from '../../Components/ProvidersDisplay';

import {
  StyledContainer,
  StyledHeader,
  StyledContentContainer,
  StyledFormContainer,
  StyledSearchImageIcon,
  StyledImageIcon,
  StyledInputContainer,
  StyledDistanceInputsContainer,
  StyledZipcodeInputContainer,
  StyledSearchRadiusContainer,
  StyledPrescriptionSearchInput,
  StyledZipcodeInput,
  StyledSearchButton,
  StyledSelectedMedicationsContainer
} from './style';

import { i18n } from '../../utils/i18n';

import { 
  capitalizeFirstLetter, 
  SEARCH_PROXIMITY_RADIUS_OPTIONS, 
  SEARCH_PROXIMITY_RADIUS_DEFAULT_SELECTED_INDEX, 
  SEARCH_PROXIMITY_RADIUS_DEFAULT_SELECTED 
} from '../../utils/utils';

import { FormControl, Autocomplete } from '@primer/react';

const PRODUCT_INVENTORY_INDEX = 'product-inventory-en';
const SEARCH_TYPE             = 'prefix';

const SearchDiscountsComponent = () => {
  const [searchInput, setSearchInput] = useState('');
  const [loadingMedications, setLoadingMedications] = useState(false);
  const [medicationSuggestions, setMedicationSuggestions] = useState([]);
  const [zipCodeValidationError, setZipCodeValidationError] = useState('');
  const [medicationInputValidationError, setMedicationInputValidationError] = useState('');
  const [selectedMedications, setSelectedMedications] = useState([]);
  const [radiusIndex, setRadiusIndex] = useState(SEARCH_PROXIMITY_RADIUS_DEFAULT_SELECTED_INDEX);

  const {
    setMedicationPrices,
    setSelectedMedicationsSelectedOffer,
    zipCode,
    setZipCode,
    searchRadiusInMiles,
    setSearchRadiusInMiles
  } = useContext(MedicationContext);

  useEffect(() => {
    setSelectedMedicationsSelectedOffer({});
    setMedicationPrices([]);
    setSearchRadiusInMiles(SEARCH_PROXIMITY_RADIUS_DEFAULT_SELECTED.id);
  }, []);

  const navigate = useNavigate();

  const isZipCodeValid = zipCode => {
    const zipCodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
    return zipCodeRegex.test(zipCode);
  };

  const searchPrescriptionPrices = () => {
    const isValidZipCode = isZipCodeValid(zipCode);
    const isValidMedication = !R.isEmpty(selectedMedications);

    if (!isValidZipCode || !isValidMedication) {
      !isValidZipCode && setZipCodeValidationError(i18n('enterValidZipCode'));
      !isValidMedication && setMedicationInputValidationError(i18n('enterAtLeastOneMedication'));
      return;
    }

    if (R.isEmpty(selectedMedications)) return;
    const joinByProp = p => R.compose(R.join(','), R.pluck(p));
    const medicationNames = joinByProp('text')(selectedMedications);
    const medicationUuids = joinByProp('id')(selectedMedications);
    navigate(`/medication-prices?medications=${medicationNames}&uuids=${medicationUuids}&zipCode=${zipCode}&radius=${searchRadiusInMiles}`);
  };

  const onMedicationSelectedChange = newlySelectedItems => {
    if (!Array.isArray(newlySelectedItems)) {
      return;
    }
    setSelectedMedications(prev => R.uniq([...prev, newlySelectedItems[0]]));
    setSearchInput('');
    setMedicationInputValidationError('');
  };

  const normalizeMedicationName = R.compose(
    R.defaultTo(''),
    R.join(' '),
    R.map(capitalizeFirstLetter),
    R.split(' '),
    R.toLower
  );

  const getMedicationsByQuery = query => {
    if (!loadingMedications) {
      setLoadingMedications(true);
      return search({ index : PRODUCT_INVENTORY_INDEX, searchType : SEARCH_TYPE })(query)
        .then(R.pathOr([], ['data']))
        .then(filteredMedications => {
          const _medications = R.compose(
            R.defaultTo([]),
            R.map(data => ({ text : normalizeMedicationName(data?.proprietaryName), id : data?.uuid })),
            R.uniqBy(R.compose(R.toLower, R.prop('proprietaryName')))
          )(filteredMedications);
          setMedicationSuggestions(_medications);
        })
        .finally(() => setLoadingMedications(false));
    }
  };
  const getMedicationsDebounced = useDebouncedCallback(getMedicationsByQuery, 300);

  const onAutocompleteInputChange = e => {
    const query = e?.target?.value;
    setSearchInput(query);
    if(query && query !== '') {
      getMedicationsDebounced(query);
    } else {
      setMedicationSuggestions([]);
    }
  };

  const removeUuidFromSearch = ({ id }) => () => {
    const updatedSearchMedications = R.reject(R.propEq(id, 'id'))(selectedMedications);
    setSelectedMedications(updatedSearchMedications);
  };

  const onLocationInputChange = e => {
    const zipcodeInput = e?.target?.value;
    setZipCode(zipcodeInput);
    setZipCodeValidationError('');
  };

  const onSearchRadiusChange = e => {
    const searchRadius = e?.id;
    setSearchRadiusInMiles(searchRadius);

    const index = R.findIndex(R.propEq(e?.name, 'name'), SEARCH_PROXIMITY_RADIUS_OPTIONS);
    setRadiusIndex(index);
  };

  return (
    <section id="search-discounts">
      <StyledContainer>
        <StyledContentContainer>
          <StyledHeader as='h1'>{i18n('searchDiscountsHeader')}</StyledHeader>
          <p>{i18n('searchDiscountsDescription')}</p>
        </StyledContentContainer>
        <StyledFormContainer>
          <StyledInputContainer id="search-prescriptions-input-container">
            <FormControl>
              <FormControl.Label id="search-prescriptions-label" visuallyHidden={true}>Search prescription drugs</FormControl.Label>
              <Autocomplete>
                <StyledPrescriptionSearchInput
                  leadingVisual={<StyledSearchImageIcon />}
                  placeholder={i18n('searchDiscountsPlaceholder')}
                  value={searchInput}
                  onChange={onAutocompleteInputChange}
                />
                <Autocomplete.Overlay id="search-prescriptions-autocomplete-overlay" anchorSide="outside-left">
                  <Autocomplete.Menu
                    items={medicationSuggestions}
                    selectedItemIds={[]}
                    aria-labelledby="autocompleteLabel-sortAfterClose"
                    selectionVariant="single"
                    onSelectedChange={onMedicationSelectedChange}
                    emptyStateText={i18n('noSearchResults')}
                    loading={loadingMedications}
                  />
                </Autocomplete.Overlay>
              </Autocomplete>
              { medicationInputValidationError && <FormControl.Validation variant='error'>{medicationInputValidationError}</FormControl.Validation> }
            </FormControl>
          </StyledInputContainer>
          <StyledDistanceInputsContainer>
            <StyledZipcodeInputContainer>
              <StyledZipcodeInput
                leadingVisual={<StyledImageIcon />}
                placeholder={i18n('zipCodePlaceholder')}
                value={zipCode}
                onChange={onLocationInputChange}
                validationStatus={zipCodeValidationError ? 'error' : 'success'}
              />
              { zipCodeValidationError && <FormControl.Validation variant='error'>{zipCodeValidationError}</FormControl.Validation> }
            </StyledZipcodeInputContainer>
            <StyledSearchRadiusContainer>
              <Dropdown
                options={SEARCH_PROXIMITY_RADIUS_OPTIONS}
                selectedIndex={radiusIndex}
                placeholder={i18n('searchRadius')}
                onChange={onSearchRadiusChange}
                id='search-radius-dropdown'
              />
            </StyledSearchRadiusContainer>
          </StyledDistanceInputsContainer>
          <StyledSearchButton type='primary' onClick={searchPrescriptionPrices}>{i18n('findPrices')}</StyledSearchButton>
        </StyledFormContainer>
        <StyledSelectedMedicationsContainer>
          {
            selectedMedications.map(selected => (
              <Token
                text={selected.text}
                hideRemoveButton={false}
                key={`search-selected-${selected.id}`}
                onRemove={removeUuidFromSearch(selected)}
              />
            ))
          }
        </StyledSelectedMedicationsContainer>
        <ProvidersDisplayComponent />
      </StyledContainer>
    </section>
  );
};

export default SearchDiscountsComponent;
